<template>
  <div class="max-width" style="min-height: 650px">
    <div class="container">
      <!-- 目录条 -->
      <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
        <a-breadcrumb>
          <a-breadcrumb-item>
            <router-link :to="'/'">
              <a-icon type="home" />首页
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>个人中心</a-breadcrumb-item>
        </a-breadcrumb>
      </a-row>
      <!-- 功能区 -->
      <a-row type="flex" justify="space-between">
        <!-- 左侧订单 -->
        <div class="info">
          <a-spin :spinning="spinning" size="large" tip="加载中">
            <a-row type="flex" justify="space-around">
              <div
                class="button"
                v-bind:key="button.name"
                @click="$router.push(button.to)"
                v-for="(button, index) in [
                  { name: '待付款', to: { name: 'orders', params: { tab: '2' } }, icon: 'icon-daifukuan', bg: 'linear-gradient(89.9deg, rgb(255, 190, 32) 0.6%, rgb(251, 112, 71) 124.3%)'},
                  { name: '待发货', to: { name: 'orders', params: { tab: '5' } }, icon: 'icon-daifahuo', bg: 'linear-gradient(109.6deg, rgb(120, 212, 234) 11.2%, rgb(206, 216, 253) 82.1%)'},
                  { name: '待收货', to: { name: 'orders', params: { tab: '5' } }, icon: 'icon-daishouhuo', bg: 'linear-gradient(107deg, rgb(13, 198, 180) 38.1%, rgb(33, 198, 138) 79.5%)'},
                  { name: '已收货', to: { name: 'orders', params: { tab: '5' } }, icon: 'icon-yishouhuo', bg: 'linear-gradient(104.9deg, rgb(255, 95, 162) 2.3%, rgb(254, 201, 154) 92.7%)'},
                  { name: '售后退货', to: { name: 'orders', params: { tab: '9' } }, icon: 'icon-tuihuozhong', bg: 'radial-gradient(100px at 10.4% 22.3%, rgb(245, 235, 238) 0%, rgb(186, 190, 245) 93.6%)'},
                ]"
              >
                <div class="circle" :style="'background:'+button.bg">
                  <icon-font style="margin-left: -1px" :type="button.icon" />
                </div>
                <div class="word">{{ button.name }} {{ spinning ? '' : `(${orderCount[index]})` }}</div>
              </div>
            </a-row>
          </a-spin>
          <!-- 各类工具 -->
          <!-- { name: '关联微信', action: hideModal, icon: 'icon-weixin' }, -->
          <div style="background: #eee; padding: 5px 20px">常用功能</div>
          <a-row type="flex">
            <div
              class="block"
              v-bind:key="button.name"
              @click="button.action"
              v-for="button in [
                { name: '平台公告', action: () => $router.push('/announcement'), icon: 'icon-gonggao' },
                { name: '申请开票', action: () => $router.push('/invoice/apply'), icon: 'icon-shenqing' },
                { name: '定制订单', action: () => $router.push({ name: 'orders', params: { tab: '15' } }), icon: 'icon-dingzhi' },
                { name: '我的账户', action: () => modalName.account = true, icon: 'icon-zhanghu' },
                { name: '送货地址', action: () => { modalData = Object.assign({}, user); modalName.address = true }, icon: 'icon-dizhi' },
                { name: '开票设置', action: () => modalName.invoice = true, icon: 'icon-kaipiao' },
                { name: '重置密码', action: () => $router.push('/password'), icon: 'icon-mima' },
                { name: '重置手机', action: () => $router.push('/phone'), icon: 'icon-shouji' },
                { name: '评价管理', action: () => $router.push('/comment'), icon: 'icon-pingjia' },
                { name: '反馈建议', action: () => modalName.feedback = true, icon: 'icon-fankui' },
                { name: '联系客服', action: () => modalName.service = true, icon: 'icon-kefu' },
              ]"
            >
              <div>
                <icon-font class="icon" :type="button.icon" />
                <div style="margin: 10px 0 30px">{{ button.name }}</div>
              </div>
            </div>
          </a-row>
        </div>
        <!-- 右侧用户绿色卡片 -->
        <div class="user" @mouseleave="showLogout = false">
          <div>
            <img src="logo/icon128.png" alt />
            <!-- 用户信息显示 -->
            <div class="user-info" :style="showLogout?'':'opacity: 1'">
              <h3 class="white" style="margin-top:30px">{{ user.hotel_name }}</h3>
              <h5 class="white">{{ user.name }} {{ user.phone }}</h5>
              <h5 class="white">{{ user.company_name }}</h5>
            </div>
            <!-- 退出提示 -->
            <div style class="user-info logout" :style="showLogout?'opacity: 1':''">
              <div style="margin: 30px 0 10px">
                <h3 class="white">退出</h3>
                <h5 class="white">您真的要退出舒悦商城吗？</h5>
              </div>
              <div v-if="showLogout" style="margin: 10px 50px">
                <a-button style="width: 100%" ghost @click="showLogout = false">再逛逛</a-button>
                <a-button style="width: 100%; margin: 20px 0" type="danger" @click="logout">退出登录</a-button>
              </div>
            </div>
          </div>
          <div class="user-option">
            <a-button @click="showLogout = !showLogout" class="red" type="link">
              {{ showLogout ? '取消' : '退出登录' }}
              <a-icon type="poweroff" />
            </a-button>
          </div>
        </div>
      </a-row>
    </div>
    <!-- 我的账户 -->
    <a-modal v-model="modalName.account" title="账户信息" :footer="null">
      <a-alert description="账户信息暂不支持修改，如有变动请联系客服。" type="warning" show-icon />
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" style="margin: 20px 0">
        <a-form-item label="用户名">{{ user.name }}</a-form-item>
        <a-form-item label="手机号">{{ user.phone }}</a-form-item>
        <a-form-item label="电子邮箱">{{ user.email }}</a-form-item>
        <a-form-item label="注册时间">{{ user.create_time }}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 送货地址 -->
    <a-modal v-model="modalName.address" title="送货地址" @ok="onModifyContact" okText="修改">
      <a-alert description="门店、地址暂不支持修改，如有变动请联系客服。" type="warning" show-icon />
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" style="margin: 20px 0">
        <a-form-item label="收货人及电话">
          <a-input v-model="modalData.contact" />
        </a-form-item>
        <a-form-item label="收货门店">{{ modalData.hotel_name }}</a-form-item>
        <a-form-item label="收货地址">{{ modalData.shipping_info }}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 开票设置 -->
    <a-modal v-model="modalName.invoice" title="开票设置" :footer="null">
      <a-alert description="开票设置暂不支持修改，如有变动请联系客服。" type="warning" show-icon />
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" style="margin: 20px 0">
        <a-form-item label="公司户名">{{ user.bank_account }}</a-form-item>
        <a-form-item label="开户行及账号">{{ user.bank_number }}</a-form-item>
        <a-form-item label="公司名称">{{ user.company_name }}</a-form-item>
        <a-form-item label="纳税人识别号">{{ user.company_code }}</a-form-item>
        <a-form-item label="公司注册地址">{{ user.company_address }}</a-form-item>
        <a-form-item label="发票接收邮箱">{{ user.email }}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 联系客服 -->
    <a-modal v-model="modalName.service" title="联系客服" :footer="null">
      <!-- <a-alert description="开票设置暂不支持修改，如有变动请联系客服。" type="warning" show-icon /> -->
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" style="margin: 20px 0">
        <a-form-item label="商城客服">暂未设置</a-form-item>
        <a-form-item label="客服电话">暂未设置</a-form-item>
        <a-form-item label="客服邮箱">暂未设置</a-form-item>
        <a-form-item label="工作时间">暂未设置</a-form-item>
      </a-form>
    </a-modal>
    <!-- 反馈建议 -->
    <a-modal
      v-model="modalName.feedback"
      title="反馈建议"
      okText="提交"
      @ok="franchiseeFeedback"
      :confirmLoading="modalLoading"
    >
      <a-alert description="如需后续联系，请附带您的联系方式。" type="warning" show-icon />
      <a-form layout="vertical" style="margin: 20px 0">
        <a-form-item label="反馈建议">
          <a-input
            type="textarea"
            style="height:200px"
            v-model="modalData.content"
            placeholder="反馈建议将投递至商城管理员"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { Icon } from 'ant-design-vue'

export default {
  components: {
    IconFont: Icon.createFromIconfontCN({
      scriptUrl: '//at.alicdn.com/t/font_2399434_761qooappwg.js'
    })
  },

  data() {
    return {
      spinning: true, // 是否加载中
      showLogout: false, // 显示退出提示
      modalName: this.hideModal(), // 弹框名称
      modalData: {}, // 弹框内参数
      modalLoading: false, // 弹框按钮加载中
      orderCount: [0, 0, 0, 0, 0],
      user: {}
    }
  },

  methods: {
    hideModal() {
      return (this.modalName = {
        account: false,
        address: false,
        invoice: false,
        feedback: false,
        service: false,
        phone: false
      })
    },
    franchiseeFeedback() {
      this.modalLoading = true
      this.post('addFeedbackForFranchisee', this.modalData)
        .then(() => {
          this.$message.success('提交成功，感谢您的反馈')
          this.modalLoading = false
          this.hideModal()
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.modalLoading = false
        })
    },
    onModifyContact() {
      this.modalLoading = true
      this.post('modifyFranchiseeForFranchisee ', {
        contact: this.modalData.contact
      })
        .then((res) => {
          this.$message.success('修改收货人名称成功')
          this.setSession('user', res)
          this.user.contact = res.contact
          this.hideModal()
          this.modalLoading = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.modalLoading = false
        })
    },
    logout() {
      this.delCookie('FRANCHISEETOKEN')
      this.delCookie('FRANCHISEEKEY')
      this.delSession('user')
      this.$router.replace('login')
    }
  },

  mounted() {
    this.user = this.getUser()
    // this.$axios
    // .all([
    // this.get('getMasterCountForFranchisee', {
    //   status: [2, 3, 4]
    // }).then((e) => (this.orderCount[0] = e)),
    // this.get('getCartCountForFranchisee', {
    //   status: [5]
    // }).then((e) => (this.orderCount[1] = e)),
    // this.get('getCartCountForFranchisee', {
    //   status: [7]
    // }).then((e) => (this.orderCount[2] = e)),
    // this.get('getCartCountForFranchisee', {
    //   status: [8, 14]
    // }).then((e) => (this.orderCount[3] = e)),
    // this.get('getCartCountForFranchisee', {
    //   status: [9, 10, 11, 12, 13]
    // }).then((e) => (this.orderCount[4] = e)),
    this.get('getOrderCountForFranchisee').then((e) => {
      this.orderCount[0] = e.not_pay
      this.orderCount[1] = e.have_paid
      this.orderCount[2] = e.not_receive
      this.orderCount[3] = e.have_receive
      this.orderCount[4] = e.refund
      this.spinning = false
    })
    // ])
    // .then(() => {
    // })
  }
}
</script>

<style scoped>
.max-width {
  max-width: 1600px;
  padding: 0 50px;
}
/* 订单 */
.info {
  flex: 1;
  margin-right: 50px;
  background-color: #fff;
  box-shadow: 0 0 20px -10px #aaa;
  border-radius: 10px;
  padding: 20px;
}
.button {
  text-align: center;
  cursor: pointer;
  color: var(--font);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.button:hover {
  transform: scale(1.08);
  color: var(--theme);
}
.button .circle {
  height: 80px;
  line-height: 83px;
  width: 80px;
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
}
.button .word {
  margin: 10px 0 30px;
}
.block {
  text-align: center;
  padding: 30px 0;
  margin: 0 -1px -1px 0;
  height: 118.6px;
  width: 118.6px;
  border: 1px solid #eee;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #888;
}
.block:hover {
  background-color: var(--theme-light);
}
.block .icon {
  color: #888;
  font-size: 40px;
  margin-left: -1px;
}
.block > div {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.block:hover > div {
  transform: scale(1.15);
}
/* 用户 */
.user {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding: 50px 0;
  width: 350px;
  height: 500px;
  border-radius: 15px;
  box-shadow: 0 0 20px -5px #aaa;
  background: linear-gradient(160deg, #16a085 10%, var(--theme) 60%);
}
.user img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 30px -5px #fff;
}
.user-option {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  text-align: right;
}
.user-info {
  position: absolute;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.logout {
  display: grid;
}
.ant-form-item {
  margin: 0;
}
</style>
